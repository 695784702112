












import {Vue, Component, Watch} from "vue-property-decorator";
import LotteryBoxType from "@/views/Other/GuessBox/components/Lottery/indexType";
import {GuessBoxStore} from "@/views/Other/GuessBox/Store";

@Component({name: "LotteryBox"})
export default class LotteryBox extends Vue implements LotteryBoxType{

    get getLottery(){
        return GuessBoxStore.getGuessBoxLottery
    }

}
