































import {Vue, Component, Watch} from "vue-property-decorator";
import IsLotteryType from "./indexType";
import {GuessBoxStore} from "@/views/Other/GuessBox/Store";

@Component({name: "IsLottery"})
export default class IsLottery extends Vue implements IsLotteryType{
    show = false
    title = ""
    data = {}

    handleClose(){
        this.show = false
    }

    handleConfirm(){
        this.handleClose()
    }

    get getLottery(){
        return GuessBoxStore.getGuessBoxLottery
    }
    @Watch("getLottery")
    changeLottery(newVal:any){
        if ( newVal ){
            this.data = newVal
            this.show = true
            let { isReward = 1 } = newVal
            if ( isReward ){
                // 未中奖
                this.title = "未中奖"
            }else{
                // 已中奖
                this.title = "恭喜您中奖了"
            }
        }
    }
}
