






































import {Vue, Component, Watch} from "vue-property-decorator";
import GuessBoxType from "./GuessBox";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import GuessBoxHead from "@/views/Other/GuessBox/components/Head/index.vue";
import GuessBoxView from "@/views/Other/GuessBox/components/Box/index.vue";
import LotteryBox from "@/views/Other/GuessBox/components/Lottery/index.vue";
import Refresh from "@/views/Other/GuessBox/components/Refresh/index.vue";
import {autoWatchNumber, getAtGuessBox, getIsLotteryGuessBox} from "@/views/Other/GuessBox/Server";
import {joinGuessBoxPlay, updateGuessBoxPlay} from "@/views/Other/GuessBox/Server";
import {Toast} from "vant";
import {GuessBoxStore} from "@/views/Other/GuessBox/Store";
import IsLottery from "@/views/Other/GuessBox/components/IsLottery/index.vue";

@Component({name: "GuessBox",components:{ HeadTop,GuessBoxHead,GuessBoxView,LotteryBox,IsLottery,Refresh }})
export default class GuessBox extends ZoomPage implements GuessBoxType{
    data:any = {}
    activeData:any = {}
    updateData:any = {}
    updateState = false
    idPlays = ""

    mounted() {
        this.loadingShow = false
        autoWatchNumber().then(res=>res)
    }

    activated(){
        this.handleUpData()
    }

    handleGetData(e:any){
        this.activeData = e
    }

    handleClickBtn(){
        let { data = {},activeData = {},updateState,updateData = {} } = this
        let { code = "" } = activeData
        let { status = "0" } = data
        if ( !(status > 0 && status) )return;
        if ( !(code + '') ){
            Toast("请选择盲盒")
            return
        }
        if ( !updateState ){
            joinGuessBoxPlay(code).then(res=>{
                Toast("参加成功")
            })
        }else{
            if ( data && data.status < 2 ){
                updateGuessBoxPlay(updateData.id,code).then(res=>{
                    this.updateData.boxId = code;
                    Toast("修改成功")
                })
            }
        }
    }

    handleUpData(bool=false){
        getAtGuessBox(this.idPlays,bool).then(res=>{
            if (res){
                this.idPlays = this.data.id
                this.data = res;
            }
        })
    }

    get getBtnTxt(){
        if ( this.data ){
            let { status = "" } = this.data
            if ( status > 1 ){
                if ( status == 2 ){
                    return "等待开奖"
                }else if ( status == 3 ){
                    return "正在发送优惠卷"
                }else{
                    return "无法更改"
                }
            }else{
                if ( this.updateState ){
                    return "更改"
                }else{
                    return "确定"
                }
            }
        }else{
            return "确定"
        }
    }

    get getBtnState(){
        let { status = "" } = this.data
        if ( status ){
            if ( status > 1 ){
                return false
            }else{
                return true
            }
        }else{
            return false
        }
    }

    get getActiveBox(){
        let { data = {} } = this
        if ( data.status < 2 && this.updateState ){
            return true
        }else{
            return false
        }
    }

    get getActiveGuess(){
        return GuessBoxStore.getGuessBoxList
    }
    @Watch("getActiveGuess")
    changeGuessList(data:any){
        if( data && data.length ){
            this.updateData = data[data.length - 1]
            this.updateState = true
        } else{
            this.updateState = false
        }
    }

    get getTitle(){
        let { title = "" } = this.$route.query
        return title || '幸运盲盒'
    }

}
