





import {Vue, Component, Emit} from "vue-property-decorator";
import ZoomPage from "@/impView/PageSafety";
import RefreshComType from "@/views/Other/GuessBox/components/Refresh/indexType";

@Component({name: "RefreshCom"})
export default class RefreshCom extends ZoomPage implements RefreshComType{
    refreshIcon = require("@/views/Other/GuessBox/Svg/refresh.svg")
    show = false

    handleClick(){
        this.show = true
        let time = setTimeout(()=>{
            this.show = false
            clearTimeout(time)
        },1000)
        this.pullRefresh()
    }

    @Emit("pullRefresh")
    pullRefresh(){
        return
    }
}
