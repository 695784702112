






















import {Vue, Component, Watch, Prop, Emit} from "vue-property-decorator";
import GuessBoxViewType from "@/views/Other/GuessBox/components/Box/indexType";
import {GuessBoxStore} from "@/views/Other/GuessBox/Store";
import {GuessList, StatusStr} from "@/views/Other/GuessBox/Model";
import {filterListKey} from "@/views/Other/GuessBox/util";

@Component({name: "GuessBoxView"})
export default class GuessBoxView extends Vue implements GuessBoxViewType{
    List:any[] = GuessList

    handleClick(data:any){
        let { code = "",active = false } = data;
        if ( this.getData && this.getData.status >= 3 ){
            let query:any = { id:this.getData.id, key:code,title:code + '号盲盒' }
            // 判断是否 中奖 盒子
            if ( this.getLottery && this.getLottery.prizeNum == code ){
                query.state = "lottery"
                query.title = "中奖盲盒列表"
            }else{
                query.state = "notLottery"
            }
            this.$router.push({
                name:"guessJoinList",
                query
            })
        }else if ( this.getData && this.getData.status == 2 || this.getData.status == 3 ){
            this.$router.push({
                name:"guessJoinList",
                query:{
                    id:this.getData.id,
                    key:code,
                    state:"look",
                    title:code + '号盲盒'
                }
            })
        }else{
            this.List = filterListKey(this.List as [],code)
            this.pullData(data)
        }
    }

    get getList(){
        return GuessBoxStore.getGuessBoxList
    }
    @Watch("getList")
    changeList(data:any[]){
        if ( data && data.length ){
            this.List = filterListKey(this.List as [],data[data.length - 1]["boxId"] || 0)
        }
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }

    get getStatusStr(){
        return this.getData && StatusStr[this.getData["status"]] || ''
    }

    get getLottery(){
        return GuessBoxStore.getGuessBoxLottery
    }

    @Emit("pullData")
    pullData(data:any){
        return data
    }
}
