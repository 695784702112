import Storage from "@/util/Storage";
import {
    autoWatchPerson,
    getJoinPlays,
    getPresentPlays,
    isLotteryGuessBox,
    joinGuessBoxSuc,
    joinPlays,
    updateJoinPlays
} from "@/Api/Other";
import {getJoinPlaysType, isLotteryGuessBoxType, JoinGuessBoxSucType} from "@/Api/Other/indexType";
import {Dialog, Toast} from "vant";
import {GuessBoxStore} from "@/views/Other/GuessBox/Store";

// 参加 游戏
export function joinGuessBoxPlay(boxId:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(joinPlays({ userId,token },{ boxId }).then(res=>{
        return res.data
    }))
}

// 修改 参加 游戏
export function updateGuessBoxPlay(id:string|number,boxId:string|number) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(updateJoinPlays({ userId,token },{ id,boxId }).then(res=>{
        return res.data
    }))
}

// 获取 信息
export function getJoinGuessBox(body:JoinGuessBoxSucType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(joinGuessBoxSuc({ userId,token },body).then(res=>{
        return res.data
    }))
}

// 获取 参加列表
export function getGuessBoxList(body:getJoinPlaysType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(getJoinPlays({ userId,token },body).then(res=>{
        return res.data
    }))
}

// 判断 是否中奖
export function getIsLotteryGuessBox(body:isLotteryGuessBoxType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(isLotteryGuessBox({ userId,token },body).then(res=>{
        GuessBoxStore.SetGuessBoxLottery(res.data)
        return res.data
    }))
}

// 自增 观看人数
export function autoWatchNumber() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(autoWatchPerson({ userId,token }).then(res=>{
        return res.data
    }))
}


// 获取 当前 游戏
export function getAtGuessBox(id:string|number,reftch?:boolean) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    if ( reftch )Toast.loading({ duration:0,message:"加载中~~~" });
    return Promise.resolve(getPresentPlays({ userId,token }).then(res=>{
        if ( res.data ){
            GuessBoxStore.SetGuessBoxData(res.data)
            if ( res.data.id != id || reftch ){
                getGuessBoxList({ pageNo:1,pageSize:100,gameId:res.data.id }).then(res=>{
                    if ( res && res.length )GuessBoxStore.SetGuessBoxList(res);
                })
                getJoinGuessBox({}).then(res=>{
                    GuessBoxStore.SetGuessBoxMessage(res)
                })
                let { status = "" } = res.data
                if ( status == 3 ){
                    // 判断是否 中奖
                    getIsLotteryGuessBox({ id:res.data.id }).then(res=>res)
                }
            }
        }else{
            Dialog.alert({
                title:"温馨提示",
                message:"当前未游戏"
            }).then(res=>res)
        }
        Toast.clear()
        return res.data
    }))
}
